<template>
  <vx-card>
    <div class="vx-col sm:w-1/1 w-full mb-base">
      <vs-tabs>
        <vs-tab label="AR Aging Report">
          <div class="tab-text">
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Source</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedSources"
                  :options="optionSources"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Operating Unit/Territory</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedOUs"
                  :options="optionOUs"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Doc Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="docDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearDocDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Posting Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="postingDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearPostingDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Due Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full flex items-center gap-4">
                <date-range-picker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="true"
                  :showDropdowns="true"
                  :autoApply="true"
                  v-model="dueDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </date-range-picker>
                <vs-button color="danger" @click="clearDueDate()"
                  >X</vs-button
                >
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Open Key Date</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <datepicker
                  ref="picker"
                  opens="center"
                  :singleDatePicker="true"
                  :timePicker="false"
                  :timePicker24Hour="false"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="true"
                  v-model="openKeyDate"
                  class="w-full"
                >
                  <template v-slot:input="picker" style="width: 100%">
                    {{ dateFormat(picker.startDate) }} -
                    {{ dateFormat(picker.endDate) }}
                  </template>
                </datepicker>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center">
                <span>Status Balance</span>
              </div>
              <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                  class="selectExample"
                  v-model="selectedStatusBalances"
                  :options="optionStatusBalances"
                  :multiple="true"
                  :allow-empty="true"
                  :group-select="false"
                  :max-height="120"
                  :limit="3"
                  placeholder="Type to search"
                  :custom-label="customLabel"
                >
                </multiselect>
              </div>
            </div>
            <div class="vx-row mb-6" style="width: 50%">
              <div class="vx-col sm:w-1/3 w-full flex items-center"></div>
              <div class="vx-col sm:w-2/3 w-full">
                <vs-button color="success" @click="handleDrawTable()"
                  >Get Data</vs-button
                >
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/1 w-full mb-base">
                <data-table
                  :baseUrl="this.baseUrl"
                  :draw="this.draw"
                  :startPostingDate="this.postingDate.startDate"
                  :endPostingDate="this.postingDate.endDate"
                  :startDocDate="this.docDate.startDate"
                  :endDocDate="this.docDate.endDate"
                  :startDueDate="this.dueDate.startDate"
                  :endDueDate="this.dueDate.endDate"
                  :openKeyDate="this.openKeyDate"
                  :ouIDs="this.ouIDs"
                  :ouNames="this.ouNames"
                  :statusBalanceIDs="this.statusBalanceIDs"
                  :statusBalanceNames="this.statusBalanceNames"
                ></data-table>
              </div>
            </div>
          </div>
        </vs-tab>
        <vs-tab label="Export">
          <div class="tab-text">
            <data-table-export></data-table-export>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import DataTable from './DataTable.vue';
import DataTableExport from './DataTableExport.vue';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    DataTable,
    DataTableExport,
    DateRangePicker,
    Datepicker,
  },
  data() {
    return {
      baseUrl: '/api/report/v1/territories',
      selectedOUs: [],
      optionOUs: [],
      selectedStatusBalances: [],
      optionStatusBalances: [],
      selectedSources: [],
      optionSources: [],
      draw: 0,
      postingDate: {
        startDate: null,
        endDate: null,
      },
      docDate: {
        startDate: null,
        endDate: null,
      },
      dueDate: {
        startDate: null,
        endDate: null,
      },
      openKeyDate: null,
      ouIDs: [],
      ouNames: [],
      statusBalanceIDs: [],
      statusBalanceNames: [],
    };
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.id === '-' ? `${val.name}` : `(${val.id}) ${val.name}`;
      }
    },
    handleClose() {
      this.detail = false;
    },

    handleOpen() {
      this.detail = true;
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format('dddd, MMMM Do YYYY');
      }
      return a;
    },
    getOUs() {
      this.$http.get('/api/report/v1/master/ou').then((resp) => {
        this.optionOUs = resp.data.records;
      });
    },
    getStatusBalances() {
      this.$http.get('/api/report/v1/master/status-balance').then((resp) => {
        this.optionStatusBalances = resp.data.records;
      });
    },
    getSources() {
      this.$http.get('/api/report/v1/master/source').then((resp) => {
        this.optionSources = resp.data.records;
      });
    },
    handleDrawTable() {
      this.draw++;
    },
    clearDocDate() {
      this.docDate = {
        startDate: null,
        endDate: null,
      };
    },
    clearPostingDate() {
      this.postingDate = {
        startDate: null,
        endDate: null,
      };
    },
    clearDueDate() {
      this.dueDate = {
        startDate: null,
        endDate: null,
      };
    },
  },
  mounted() {
    this.getOUs();
    this.getStatusBalances();
    this.getSources();
  },
  watch: {
    selectedOUs(val) {
      let ouIDs = [];
      let ouNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          ouIDs.push(e.id);
          ouNames.push(e.name);
        }
      });

      this.ouIDs = ouIDs;
      this.ouNames = ouNames;
    },
    selectedStatusBalances(val) {
      let statusBalanceIDs = [];
      let statusBalanceNames = [];

      val.forEach((e) => {
        if (e.ID != 0) {
          statusBalanceIDs.push(e.id);
          statusBalanceNames.push(e.name);
        }
      });

      this.statusBalanceIDs = statusBalanceIDs;
      this.statusBalanceNames = statusBalanceNames;
    },
  },
};
</script>

<style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>
